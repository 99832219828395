import { Injectable } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { ListResult, UploadMetadata } from '@angular/fire/compat/storage/interfaces';
import { Observable } from 'rxjs';

export type UploadType = File | Blob | ArrayBuffer | Uint8Array;

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private storage: AngularFireStorage) {}

  /**
   * Upload or update a new file to the storage reference's path
   *
   * @param {string} path The path where the file should be uploaded.
   * @param {UploadType} data The upload data to be uploaded.
   * @param {UploadMetadata} metadata The upload metadata
   * @return {AngularFireUploadTask} The upload task.
   */
  public upload(
    path: string,
    data: UploadType,
    metadata?: UploadMetadata,
  ): AngularFireUploadTask {
    return this.storage.upload(path, data, metadata);
  }

  /**
   * List all items (files) and prefixes (folders) under this storage reference.
   *
   * @remarks
   * This is a helper method for calling list() repeatedly until there are
   * no more results. The default pagination size is 1000.
   *
   * Note: The results may not be consistent if objects are changed while this
   * operation is running.
   *
   * Warning: `listAll` may potentially consume too many resources if there are
   * too many results.
   *
   * @param {string} path The path to list all of items and prefixes from.
   * @return {Observable<ListResult>} An observable of the list result.
   *
   */
  public listAll(path: string): Observable<ListResult> {
    return this.storage.ref(path).listAll();
  }

  /**
   * Deletes the object at this location.
   *
   * @param {string} path The path to the object.
   * @return {Observable<any>} An observable of the deletion result.
   */
  public deleteFile(path: string): Observable<any> {
    return this.storage.ref(path).delete();
  }

  /**
   *
   * Get file reference
   * @param {string} path The path to the file
   * @return {AngularFireStorageReference}
   * */
  public getFileRef(path: string): AngularFireStorageReference {
    return this.storage.ref(path);
  }
}
